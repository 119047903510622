@import 'assets/styles/_base.scss';

// TODO: temporal
.app__content{
  padding-left: 0;
  padding-right: 0;
}

.error_message{
  &--red {
    color: $red !important;
    text-align: start;
  }
}

.disable {
  opacity: 0.4;
}

.only-Desktop {
  display: none;
  @include desktop {
    display: block;
  }
  @include tablet-large {
    display: block;
  }
}

.only-mobile {
  cursor: pointer;
  @include desktop {
    display: none;
  }
  @include tablet-large {
    display: none;
  }
}
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ACB4B9;
  outline: none;
  margin-right: 5px;
}

input[type="radio"]:checked {
  border: 2px solid #6F4EE5;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 50%;
  background: #6F4EE5;
}

.global{
  &__ErrorInput{
    color: #D92D20;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: start;
  }
  &__input__text{
    color: red;
  }
  &__center {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__btnWhite{
    width: 80% !important;
    margin-left: 40px !important;
    color: #6F4EE5 !important;
    background-color: #F2EEFF !important;
    margin-bottom: 20px !important;
    @include desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }
    &:hover {
      color: #6F4EE5 !important;
      background-color: #F2EEFF !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }
  &__btnGreen{
    width: 80% !important;
    margin-left: 40px !important;
    @include desktop {
      width: 30% !important;
      margin-left: 35% !important;
    }
    &:hover {
      background: #6F4EE5 !important;
      color: #F2EEFF !important;
      box-shadow: 0 4px 10px rgba(20, 43, 127, 0.25);
    }
  }
}