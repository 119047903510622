@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

body {
    margin: 0;
}

*,
.MuiTypography-root,
.MuiListItemText-primary,
.MuiTypography-body1 {
    font-family: 'Montserrat', sans-serif;
}

#react-simple-modal-container {
  .modal__main {
    min-height: auto !important;

    .rsm-flex-1 {
      padding: 0;
    }
  } 
}
.btn{
  background:#6F4EE5 !important;
}
.btn:disabled{
  background:#E2DAFC !important;
  color: #6F4EE5 !important;
  opacity: 99% !important;
}
.checkbox label .check {
  border: 2px #FFFFFF solid !important;
}
.checkbox label input:checked ~ .check {
  background-color: #6F4EE5 !important;
  border: 1px #FFFFFF solid !important;
}
.dropDown__opcion:hover {
  background: #F2EEFF !important;
}
.inputUnderLine {
  border-bottom: 3px solid #6F4EE5 !important;
  caret-color: #6F4EE5 !important;
}
.spinner {
  border-top-color: #3ACF9B !important;
}